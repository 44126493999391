@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
}

@font-face {
  font-family: "NotoSansTC";
  src:
    local("NotoSansTC"),
    url("./font/NotoSansTC-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSansTC-Bold";
  src:
    local("NotoSansTC-Bold"),
    url("./font/NotoSansTC-Bold.ttf") format("truetype");
}

.plane {
  background-image: url("./image/plane.jpg");
}

.gradient-underline {
  display: inline;
  background-image: linear-gradient(
    90deg,
    #7ea0f5 60%,
    #60bffd 80%,
    #b1b1fd 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 4.5px;
  background-position: 50% 100%;
}

.left-mark {
  border-radius: 10% 10% 0% 0% / 40% 40% 0% 0%;
  filter: drop-shadow(4px -8px 4px rgb(200, 200, 200, 0.2));
}

.left-mark::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  right: -20px;
  bottom: 0;
  background: radial-gradient(circle at 100% 0, transparent 20px, #ffffff 21px);
}

.right-mark {
  border-radius: 10% 10% 0% 0% / 40% 40% 0% 0%;
  filter: drop-shadow(-4px -8px 4px rgb(200, 200, 200, 0.2));
}

.right-mark::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: -20px;
  bottom: 0;
  background: radial-gradient(circle at 0 0, transparent 20px, #ffffff 21px);
}

.bottom-mark {
  filter: drop-shadow(4px -7px 4px rgb(200, 200, 200, 0.2));
}

.check {
  background-image: url("./image/success_2.svg ");
}

.cross {
  background-image: url("./image/cross.svg");
}

.failed {
  background-image: url("./image/failed.svg");
}

/* 隱藏原生的 checkbox，但仍然保持其可訪問性 */
.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  background-color: white;
  width: 24px;
  height: 24px;
  border: 2px solid #545454;
  cursor: pointer;
  border-radius: 4px;
}

/* 當 checkbox 被選中時的樣式 */
.custom-checkbox:checked {
  background-color: #f97316; /* Tailwind CSS 的橘色 */
  background-image: url("./image/check.svg"); /* 替換成你的打勾圖案的路徑 */
  background-repeat: no-repeat;
  background-position: center;
}

/* 選中時去掉自定義的對勾文字 */
.custom-checkbox:checked:after {
  content: "";
}

.arrowdown {
  background-image: url("./backend/images/arrowdown.svg");
  background-position: right 12px center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
}

/* input 元素的自定義樣式 */
.Input {
  border: 1px solid #ccc;
  color: #545454; /* 這裡是 text-[#545454] 的轉換 */
  line-height: 1.5;
  outline: none;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;
}

/* focus 狀態下的樣式 */
.Input:focus {
  border-width: 1px;
  border-color: #4da8c5;
  outline: none;
  box-shadow: 0 0 0 4px rgba(77, 168, 197, 0.2);
}

.circle {
  position: relative;
  width: 30px; /* 縮小寬度 */
  height: 30px; /* 縮小高度 */
  border-radius: 50%;
}

.circle::before {
  content: "";
  position: absolute;
  top: 3px; /* 調整位置 */
  left: 3px; /* 調整位置 */
  width: 24px; /* 縮小寬度 */
  height: 24px; /* 縮小高度 */
  background-color: transparent;
  border: 3px solid white; /* 縮小邊框 */
  border-top: 3px solid #4da8c5;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  /* 阻尼動畫 */
  /* animation: spin 2s cubic-bezier(0.25, 1, 0.5, 1) infinite; */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px) {
  .react-datepicker {
    width: 100% !important; /* 設定 DatePicker 在 480px 以下寬度為 100% */
  }

  .react-datepicker__month-container {
    width: 100% !important; /* 設定 Month Container 在 480px 以下寬度為 100% */
  }
}

input[type="password"]::-ms-reveal {
  display: none;
}

input[type="password"]::-ms-clear {
  display: none;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange {
  background-color: #4da8c5 !important; /* 更改選中日期的背景顏色 */
  border-color: #4da8c5 !important; /* 更改選中日期的邊框顏色 */
  color: #ffffff !important; /* 更改選中日期的字體顏色 */
}
.flatpickr-weekdays,
.flatpickr-weekday,
.flatpickr-month,
.flatpickr-monthDropdown-months {
  background-color: #4da8c5 !important; /* 更改星期的背景顏色 */
}
